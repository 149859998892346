import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the thunk for fetching products
export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  const productsUrl = `${process.env.REACT_APP_API_URL}/products`;
  const response = await axios.get(productsUrl);
  // Process products similarly to how you did in the component
  const processedProducts = response.data.map(product => {
    const ytLinkRegex = /<p>yt\|(https:\/\/www\.youtube\.com\/watch\?v=[^<]+)<\/p>/g;
    let match;
    const youtubeLinks = [];
    let updatedDescription = product.description;

    while ((match = ytLinkRegex.exec(product.description)) !== null) {
      youtubeLinks.push(match[1]);
      updatedDescription = updatedDescription.replace(match[0], '');
    }

    return {
      ...product,
      description: updatedDescription,
      youtubeLinks,
    };
  });

  return processedProducts.sort((a, b) => b.name.toUpperCase().localeCompare(a.name.toUpperCase()));

});

const productsSlice = createSlice({
    name: 'products',
    initialState: {
      items: [],
      loading: false,
      error: null,
    },
    reducers: {
      // Your reducers here
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchProducts.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchProducts.fulfilled, (state, action) => {
          state.loading = false;
          state.items = action.payload;
        })
        .addCase(fetchProducts.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });
  
  export default productsSlice.reducer;