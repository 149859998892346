import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import './ImagesCarousel.css';

export default class ImageCarousel extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.molasses
  };

  slides = this.props.images.map((imageSrc, index) => ({
    key: uuidv4(),
    content: <img src={imageSrc} alt={`Slide ${index}`} />,
    onClick: () => this.setState({ goToSlide: index })
  }));

  componentDidMount() {
    this.autoChangeSlide();
  }

  componentWillUnmount() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }

  autoChangeSlide = () => {
    const slideChangeDelay = 2400; // Change slide every 3000 milliseconds (3 seconds)

    this.slideInterval = setInterval(() => {
      this.setState(prevState => ({
        goToSlide: (prevState.goToSlide + 1) % this.slides.length // Loop back to the first slide after the last slide
      }));
    }, slideChangeDelay);
  };

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };

  render() {
    return (
      <div className="super-carousel-css">
        <Carousel
          slides={this.slides}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
      </div>
    );
  }
}