import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import ContactHero from './Components/ContactHero'
import Intro from './Components/Intro/Intro'
import Locations from './Components/Locations/Locations'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet-async'

const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Contact</title>
        <meta name='description' content="Get in touch with our PR Consulting team. Contact us for innovative solutions in brand imaging and public relations. Let's connect and transform your brand together." />
        <link rel='canonical' href='/Contact' />
        <meta name="keywords" content="Media M, Media M PL SP ZOO, Media M Slovenia, Media M Poland, Media M slo, Business Consulting, Business Counselling, Food Tech Slovenia, 3D Food printer slovenia, Food Fabricator Slovenia, Healthy food, 3D Food printer Slovenia, IoT, AI 3D Food Printer" />
      </Helmet>
      <Navbar />
      <ContactHero />
      <Intro />
      <Locations />
      <Footer />
    </div>
  )
}

export default Contact
