import React, { useEffect, useRef } from 'react';
import './Hero.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // basic Swiper styles
import 'swiper/css/pagination'; // optional for pagination
import { Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import image from '../../../../Resources/Images/Triglav-National-Park.jpg'
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import ft1 from '../../../../Resources/Images/c1.png'
import ft2 from '../../../../Resources/Images/c2.png'
import ft3 from '../../../../Resources/Images/c3.png'
import ft4 from '../../../../Resources/Images/c4.png'
import ft5 from '../../../../Resources/Images/c5.png'
import ft6 from '../../../../Resources/Images/c6.png'
import ft7 from '../../../../Resources/Images/c7.png'

SwiperCore.use([Pagination]);


const Hero = () => {
    const heroHeaderRef = useRef(null); // Create a ref for the hero-header
    const navigate = useNavigate(); // Initialize useNavigate




    useEffect(() => {


        setTimeout(() => {
            const heroShadows = document.querySelector('.hero-shadows');
            heroShadows.classList.add('show-shadow');

            const heroShadows2 = document.querySelector('.super-shadows');
            heroShadows2.classList.add('show-shadows');
        }, 100)

        setTimeout(() => {
            const heroHeader = document.querySelector('.hero-header');
            heroHeader.classList.add('move-up');

        }, 300)

        setTimeout(() => {
            const heroHeader = document.querySelector('.hero-paragraph');
            heroHeader.classList.add('move-up');

        }, 500)

        setTimeout(() => {
            const heroHeader = document.querySelector('.hero-button1');
            heroHeader.classList.add('move-up');

        }, 700)

        setTimeout(() => {
            const heroHeader = document.querySelector('.hero-button2');
            heroHeader.classList.add('move-up');

        }, 900)
    }, [])


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo(heroHeaderRef.current,
            { y: 0, opacity: 1 }, // Starting state
            {
                y: 0, // Move up by 200px
                opacity: 0, // Fade out
                duration: 1,
                scrollTrigger: {
                    trigger: ".hero-main", // Use .hero-main as the trigger for the scroll animation
                    start: "top top", // Animation will start when the top of .hero-main hits the top of the viewport
                    end: "bottom center", // Animation will end when the bottom of .hero-main exits the center of the viewport
                    scrub: 1, // Smoothly links the animation progress to the scroll position
                    markers: false,
                }
            }
        );

        document.querySelectorAll('.swiper-slide-bg').forEach(slideBg => {
            gsap.fromTo(slideBg,
                { y: 0 },
                {
                    y: 250, // Move up by 200px
                    duration: 1,
                    scrollTrigger: {
                        trigger: slideBg, // Each .swiper-slide-bg is its own trigger
                        start: "top top", // Animation will start when the top of the slideBg is at the bottom of the viewport
                        end: "bottom top", // Animation will end when the top of the slideBg exits the top of the viewport
                        scrub: 1, // Smoothly links the animation progress to the scroll position
                        markers: false,
                    }
                }
            );
        });



    }, []);


    const handleNavigation = (path) => {
        window.scrollTo({
            top: 0,
        });

        navigate(path); // Navigate to the specified path
    };



    return (
        <div className='hero-main'>

            <div
                className='swiper-slide-bg'
                style={{ backgroundImage: `url(${image})` }}
            ></div>

            <div className='hero-shadows'>

                <div className='hero-wrapper' ref={heroHeaderRef}>
                    <div className='text-wrapprer' >
                        <h1 className='hero-header' >
                            PROJECTS
                        </h1>
                    </div>
                    <div className='text-wrapprer'>
                        <p className='hero-paragraph'>
                            A Spectrum of Creativity and Innovation
                        </p>
                    </div>

                    <div className='buttons-row'>
                        <div className='text-wrapprer'>
                            <button className='hero-button1' onClick={() => handleNavigation('/Contact')}>Contact</button>
                        </div>

                        <div className='text-wrapprer'>
                            <button className='hero-button2' onClick={() => handleNavigation('/About')}>About</button>
                        </div>
                    </div>

                </div>
            </div>

            <div className='super-shadows'>

            </div>
        </div>
    )
}

export default Hero
