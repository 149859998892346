import React, { useEffect, useState } from 'react';
import './Navbar.css';
import logo from '../../Resources/Images/images.png'
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useLocation } from 'react-router-dom';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Your existing useEffect code here

        // Scroll event listener
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) { // Adjust 50 to the scroll position you prefer
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const toggleMenu = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(!isMenuOpen);
        }
        else {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    const location = useLocation();

  useEffect(() => {
    // Check if the current path is not "/"
    if (location.pathname !== "/") {
      // Your timeouts to add 'move-up' class
      /*setTimeout(() => {
          const heroHeader = document.querySelector('.the-logo');
          if (heroHeader) heroHeader.classList.add('move-up');
      }, 1100);*/

      setTimeout(() => {
          const heroHeader = document.querySelector('.navigation');
          if (heroHeader) heroHeader.classList.add('move-up');
      }, 1300);

      setTimeout(() => {
          const heroHeader = document.querySelector('.navigation2');
          if (heroHeader) heroHeader.classList.add('move-up');
      }, 1500);

      setTimeout(() => {
          const heroHeader = document.querySelector('.navigation4');
          if (heroHeader) heroHeader.classList.add('move-up');
      }, 1700);

      setTimeout(() => {
          const heroHeader = document.querySelector('.navigation5');
          if (heroHeader) heroHeader.classList.add('move-up');
      }, 1900);

      setTimeout(() => {
          const heroHeader = document.querySelector('.navigation3');
          if (heroHeader) heroHeader.classList.add('move-up');
      }, 2100);
    }
  }, [location]); 
    const handleNavigation = (path) => {
        window.scrollTo({
            top: 0,
        });

        navigate(path); // Navigate to the specified path
        setIsMenuOpen(false); // Optionally close the menu after navigating
    };

    return (
        <div className={`navbar-main ${isMenuOpen ? 'show-white' : ''} ${isScrolled ? 'scrolled-navbar' : ''}`}>
            <div className='navbar-row'>
                <div className='navbar-left'>
                    <div className='logo-wrapper'>
                        <img alt='logo' className={`the-logo ${isScrolled ? 'scrolled-logo move-up' : 'move-up'}`} src={logo} onClick={() => handleNavigation('/')} />
                    </div>
                </div>
                <div className='navbar-right'>
                    <div className='navigations-row'>
                        <div className='text-wrapprer' onClick={() => handleNavigation('/Projects')}>
                            <p className={`navigation ${isScrolled ? 'scrolled-navigation ' : 'move-up'}`}>
                                Projects
                            </p>
                        </div>
                        <div className='text-wrapprer' onClick={() => handleNavigation('/About')}>
                            <p className={`navigation2 ${isScrolled ? 'scrolled-navigation ' : 'move-up'}`}>
                                About Us
                            </p>
                        </div>
                        <div className='text-wrapprer' onClick={() => handleNavigation('/Experiences')}>
                            <p className={`navigation4 ${isScrolled ? 'scrolled-navigation ' : 'move-up'}`}>
                                Experiences
                            </p>
                        </div>
                        <div className='text-wrapprer' onClick={() => handleNavigation('/ProductManagement')}>
                            <p className={`navigation5 ${isScrolled ? 'scrolled-navigation ' : 'move-up'}`}>
                                Product Management
                            </p>
                        </div>
                        <div className='text-wrapprer' onClick={() => handleNavigation('/Contact')}>
                            <p className={`navigation3 ${isScrolled ? 'scrolled-navigation ' : 'move-up'}`}>
                                Contact
                            </p>
                        </div>
                    </div>

                    <div className='icons-wrapprer'>
                        <div className={`menu-icon ${isMenuOpen ? 'black-icon' : ''} `} onClick={toggleMenu}>
                            <div className={`menu-bar ${isMenuOpen ? 'bar1' : ''} ${isScrolled ? 'scrolled-nav' : ''}`}></div>
                            <div className={`menu-bar ${isMenuOpen ? 'bar2' : ''} ${isScrolled ? 'scrolled-nav' : ''}`}></div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={`hidden-menu ${isMenuOpen ? 'show-menu' : ''} ${isScrolled ? 'scrolled-hidden' : ''}`}>
                <div className='navigations-wrapper'>
                    <p className='open-navigation' onClick={() => handleNavigation('/Projects')}>PROJECTS</p>
                    <p className='open-navigation' onClick={() => handleNavigation('/About')}>ABOUT US</p>
                    <p className='open-navigation' onClick={() => handleNavigation('/Experiences')}>EXPERIENCES</p>
                    <p className='open-navigation' onClick={() => handleNavigation('/ProductManagement')}>PRODUCT MANAGEMENT</p>
                    <p className='open-navigation' onClick={() => handleNavigation('/Contact')}>CONTACT</p>
                </div>

            </div>
        </div>
    )
}

export default Navbar
