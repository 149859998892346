import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './App.css';
import Main from './Navigations/Main/Main';
import LoadingPage from './Components/LoadingPage/LoadingPage';
import About from './Navigations/About/About';
import Experiences from './Navigations/Experiences/Experiences';
import Pmanagement from './Navigations/Pmanagement/Pmanagement';
import Contact from './Navigations/Contact/Contact';
// Adjust the import path as necessary

function App() {
  const isLoading = useSelector((state) => state.nonPersisted.loadingProducts.LoadingValue);

  {/*const [isLoading2, setIsLoading2] = useState(() => {
    const storedIsLoading2 = localStorage.getItem('isLoading2');
    return storedIsLoading2 !== null ? JSON.parse(storedIsLoading2) : true;
  });

  // Store isLoading2 in local storage
  useEffect(() => {
    localStorage.setItem('isLoading2', JSON.stringify(isLoading2));
  }, [isLoading2]);*/}

  return (
    <Router>

      <Routes>
        <Route path="/" element={<LoadingPage />} />

        <Route path="/Projects" element={<Main />} />
        <Route path="/About" element={<About />} />
        <Route path="/Experiences" element={<Experiences />} />
        <Route path="/ProductManagement" element={<Pmanagement />} />
        <Route path="/Contact" element={<Contact />} />

        {/* Add more routes as needed */}
      </Routes>

    </Router>
  );
}

export default App;