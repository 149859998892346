import React from 'react';
import './Main.css'
import Hero from './Components/Hero/Hero'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Products from './Components/Products/Products'
import { Helmet } from 'react-helmet-async';


const Main = () => {

    return (
        <div className='main-main'>
            <Helmet>
                <title>Media M</title>
                <meta name='description' content='Leading PR Consulting services focused on innovative brand imaging and public relations development to enhance connectivity and market presence.'/>
                <link rel='canonical' href='/Projects'/>
                <meta name="keywords" content="Media M, Media M PL SP ZOO, Media M Slovenia, Media M Poland, Media M slo, Business Consulting, Business Counselling, Food Tech Slovenia, 3D Food printer slovenia, Food Fabricator Slovenia, Healthy food, 3D Food printer Slovenia, IoT, AI 3D Food Printer" />

            </Helmet>
            <Navbar />
            <Hero />
            <Products />
            <Footer />
        </div>
    )
}

export default Main
