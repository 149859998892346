import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Phero from './Components/Phero'
import Pm from './Components/Pm/Pm'
import PmCore from './Components/Core/PmCore'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet-async'

const Pmanagement = () => {
  return (
    <div>
      <Helmet>
                <title>Product Management</title>
                <meta name='description' content='Navigate our suite of product management solutions. Discover tools and strategies designed to streamline development, enhance market fit, and drive product success.'/>
                <link rel='canonical' href='/ProductManagement'/>
                <meta name="keywords" content="Media M, Media M PL SP ZOO, Media M Slovenia, Media M Poland, Media M slo, Business Consulting, Business Counselling, Food Tech Slovenia, 3D Food printer slovenia, Food Fabricator Slovenia, Healthy food, 3D Food printer Slovenia, IoT, AI 3D Food Printer" />

            </Helmet>
      <Navbar/>
      <Phero/>
      <Pm/>
      <PmCore/>
      <Footer/>
    </div>
  )
}

export default Pmanagement
