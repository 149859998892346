import React from 'react'
import './Footer.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Footer = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigation = (path) => {
    window.scrollTo({
        top: 0,
    });

    navigate(path); // Navigate to the specified path
};
  return (
    <div className='footer-main'>
      <div className='footer-wrapper'>
        <div className='first-footer-row'>
          <div className='footer-column'>
            <h3 className='navigations-footer'>NAVIGATIONS</h3>
            <p className='footer-nav' onClick={() => handleNavigation('/')}>Projects</p>
            <p className='footer-nav' onClick={() => handleNavigation('/About')}>About Us</p>
            <p className='footer-nav' onClick={() => handleNavigation('/Experiences')}>Experiences</p>
            <p className='footer-nav' onClick={() => handleNavigation('/ProductManagement')}>Product Management</p>
            <p className='footer-nav' onClick={() => handleNavigation('/Contact')}>Contact</p>

          </div>

          <div className='footer-column'>
            <h3 className='navigations-footer'>CONTACT</h3>
            <div className='row-of-footer-contacts'>
              <div className='icon-wrapperr'>
                <a href="tel:+386 1 597 00 141">
                  <i className="fas fa-phone FASSS"></i> {/* Phone icon */}
                </a>
              </div>

              <div className='icon-wrapperr'>
                                <a href="mailto:info@media-m.si">
                                    <i className="fas fa-envelope FASSS"></i> {/* Mail icon */}
                                </a>
                            </div>

                            <div className='icon-wrapperr'>
                                <a href="https://www.facebook.com/MediaM.si/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f FABBB"></i> {/* Facebook icon */}
                                </a>
                            </div>


                            <div className='icon-wrapperr'>
                                <a href="https://www.linkedin.com/company/1775458/" target="_blank" rel="noopener noreferrer">
                                    <i className="FABBB fab fa-linkedin-in"></i> {/* LinkedIn icon */}
                                </a>
                            </div>


            </div>

          </div>
        </div>
        <div className='rights-reserved'>© Media-M all rights reserved</div>
      </div>

    </div>
  )
}

export default Footer
