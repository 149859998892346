import React, { useEffect, useState } from 'react';
import './Products.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts } from '../../../../Store/Slices/productsSlice';
import ImageCarousel from './ImagesCarousel';

const Products = () => {
  const products = useSelector((state) => state.persisted.products.items);
  const dispatch = useDispatch();
  const [visibleProducts, setVisibleProducts] = useState(12); // State to control the number of visible products

 

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  // Handler for the "Show More" button
  const showMoreProducts = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 12); // Increase the number of visible products
  };

  return (
    <div className='products-main'>
      {products.slice(0, visibleProducts).map(product => ( // Only map through the visible products
        <div key={product.id} className='product-holder'>
          <h2 className='products-name'>
            {product.name.includes("_") ? product.name.split("_")[1] : product.name}
          </h2>
          <p>{product.price}</p>

          <div className='content-divider'>
            <div className='divide-left'>
              {product.images && product.images.length > 0 && (
                <img src={product.images[0].src} alt="Product" className='product-image' />
              )}
            </div>
            <div className='divide-right'>
              <div dangerouslySetInnerHTML={{ __html: product.description }} className='dangerous-html'></div>
            </div>
          </div>


          {product.images && product.images.length > 1 && (
            <ImageCarousel images={product.images.slice(1).map(img => img.src)} />
          )}

              <div className='margindiv'></div>

          {product.youtubeLinks && product.youtubeLinks.map((link, index) => {
            const videoId = link.split('watch?v=')[1];
            const embedUrl = `https://www.youtube.com/embed/${videoId}`;

            return (
              <div key={index} className="youtube-video-container">
                <iframe

                  src={embedUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={`YouTube video ${index}`}
                ></iframe>
              </div>
            );
          })}

        </div>
      ))}
      {visibleProducts < products.length && (
        <div className='btn-wrapper'>
          <button onClick={showMoreProducts} className="show-more-btn">Show More</button>
        </div>
      )}
    </div>
  );
};

export default Products;