import React from 'react'
import './Expertise.css'

const Expertise = () => {
  return (
    <div className='expertise-main'>
        <h2 className='products-name my-team'>Elevating Efficiency with Expertise</h2>
        <h3 className='expertise-sub'>Unlocking Potential, Elevating Efficiency</h3>
        <p className='expertise-p'>In a world where efficiency and visibility determine success, our PR Consulting services stand at the forefront of innovation and connectivity. Specializing in brand imaging and public relations development, we harness a wealth of knowledge in technology and an extensive network of influential connections. Our mission? To streamline your operations, enhance your brand's visibility, and position your company as a leader in its field. With us, it's not just about solving problems—it's about foreseeing them and transforming challenges into opportunities for growth.</p>
    </div>
  )
}

export default Expertise
