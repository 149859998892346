import React from 'react'
import './Locations.css'
import p2 from '../../../../Resources/Images/p2.png'
import p3 from '../../../../Resources/Images/p3.png'
import slo from '../../../../Resources/Images/slo.png'
import pol from '../../../../Resources/Images/pol.png'
import p4 from '../../../../Resources/Images/newlocation.png'
import p5 from '../../../../Resources/Images/newlocation2.png'

const Locations = () => {
    return (
        <div className='locations-main'>

            


            <h3 className='expertise-sub subbmargins'>Find Us or Reach Out in Poland and Slovenia</h3>

            <div className='locations-row'>
                <div className='locations-left'>
                    <div className='country-wrapper'>
                        <h3 className='country-h'>Poland</h3>

                        <iframe className='framec' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.26258954275!2d21.003900176995376!3d52.18095386127567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471932d843eea979%3A0x275055efeea45a71!2sRodziny%20Hiszpa%C5%84skich%208%2C%2002-685%20Warszawa%2C%20Poljska!5e1!3m2!1ssl!2ssi!4v1725354858671!5m2!1ssl!2ssi" width="100%" height="320" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <img src={p5} className='location-img' />
                        <img src={p4} className='location-img' />



                        <img src={pol} className='flag-img' />

                        <div className='bullet-row rrrr' >
                            <div className='icon-wrapperr'>
                                <a href="tel:+48573495488">
                                    <i className="fas fa-phone"></i> {/* Phone icon */}
                                </a>
                            </div>
                            <p className='a-expertise-p pmargind'>+48 573 495 488</p>

                        </div>

                        <div className='bullet-row rrrr' >
                            <div className='icon-wrapperr'>
                                <a href="mailto:info@media-m.si">
                                    <i className="fas fa-envelope"></i> {/* Mail icon */}
                                </a>
                            </div>
                            <p className='a-expertise-p pmargind'>info@media-m.si</p>

                        </div>

                        <div className='bullet-row rrrr' >
                            <div className='icon-wrapperr'>
                                <a href="https://www.facebook.com/MediaM.si/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f"></i> {/* Facebook icon */}
                                </a>
                            </div>
                            <div className='icon-wrapperr'>
                                <a href="https://www.linkedin.com/company/1775458/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin-in"></i> {/* LinkedIn icon */}
                                </a>
                            </div>

                        </div>



                    </div>



                </div>
                <div className='locations-right'>
                    <div className='country-wrapper'>
                        <h3 className='country-h'>Slovenia</h3>

                        <iframe className='framec' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2767.2501549034387!2d14.479657476728638!3d46.085990891736905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477acd31fe2b935f%3A0xfb8226611829c9d5!2sStegne%2021c-1117%2C%201000%20Ljubljana!5e0!3m2!1ssl!2ssi!4v1707852622933!5m2!1ssl!2ssi" width="100%" height="320" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <img src={p2} className='location-img' />
                        <iframe className='framec' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2766.141387667265!2d14.519906676729569!3d46.108089590246976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765333f1aef7afb%3A0xed368bd9071e637!2s%C5%BDorgova%20ulica%2070%2C%201231%20Ljubljana%20-%20%C4%8Crnu%C4%8De!5e0!3m2!1ssl!2ssi!4v1707853231420!5m2!1ssl!2ssi" width="100%" height="320" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <img src={p3} className='location-img' />

                        <img src={slo} className='flag-img' />

                        <div className='bullet-row rrrr' >
                            <div className='icon-wrapperr'>
                                <a href="tel:+386 1 597 00 141">
                                    <i className="fas fa-phone"></i> {/* Phone icon */}
                                </a>
                            </div>
                            <p className='a-expertise-p pmargind'>+386 1 597 00 141</p>

                        </div>

                        <div className='bullet-row rrrr' >
                            <div className='icon-wrapperr'>
                                <a href="tel:+386 41 618 555">
                                    <i className="fas fa-phone"></i> {/* Phone icon */}
                                </a>
                            </div>
                            <p className='a-expertise-p pmargind'>+386 41 618 555</p>

                        </div>

                        <div className='bullet-row rrrr' >
                            <div className='icon-wrapperr'>
                                <a href="mailto:info@media-m.si">
                                    <i className="fas fa-envelope"></i> {/* Mail icon */}
                                </a>
                            </div>
                            <p className='a-expertise-p pmargind'>info@media-m.si</p>

                        </div>

                        <div className='bullet-row rrrr' >
                            <div className='icon-wrapperr'>
                                <a href="https://www.facebook.com/MediaM.si/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f"></i> {/* Facebook icon */}
                                </a>
                            </div>
                            <div className='icon-wrapperr'>
                                <a href="https://www.linkedin.com/company/1775458/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin-in"></i> {/* LinkedIn icon */}
                                </a>
                            </div>

                        </div>


                    </div>


                </div>
            </div>
        </div>
    )
}

export default Locations
