import Navbar from '../../Components/Navbar/Navbar';
import './About.css'
import React from 'react';
import AboutHero from './Components/AboutHero';
import Ekipa from './Components/Ekipa/Ekipa';
import Expertise from './Components/Expertise/Expertise';
import AboutWork from './Components/AboutWork/AboutWork';
import Footer from '../../Components/Footer/Footer';
import { Helmet } from 'react-helmet-async';
import Business from './Components/Business/Business';



const About = () => {
  return (
    <div className='about-main'>
      <Helmet>
                <title>About Us</title>
                <meta name='description' content='Meet our team of PR experts dedicated to revolutionizing brand imaging and public relations. Learn about our journey, values, and commitment to innovation and connectivity.'/>
                <link rel='canonical' href='/About'/>
            </Helmet>
      <Navbar/>
      <AboutHero/>
      <Ekipa/>
      <Expertise/>
      <AboutWork/>
      <Business/>
      <Footer/>
    </div>
  )
}

export default About
