import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import productsReducer from './Slices/productsSlice';
import loadingReducer from './Reducers/loadingReducer';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import teamReducer from './Slices/teamSlice';

// Persist config for the parts of the state you want to persist
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2, // Helps in merging the initial and persisted states
  // Explicitly listing which reducers to persist
  whitelist: ['products'], // Add other reducers you want to persist
};

const rootReducer = combineReducers({
  // Persisted reducers
  products: productsReducer,
  team: teamReducer, // Added here

  // other reducers you want to persist
});

const nonPersistedReducer = combineReducers({
  // Non-persisted reducers
  loadingProducts: loadingReducer,
  // other non-persisted reducers if any
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Enhance the persisted reducer with non-persisted ones
const combinedReducer = combineReducers({
  persisted: persistedReducer,
  nonPersisted: nonPersistedReducer,
});

export const store = configureStore({
  reducer: combinedReducer,
});

export const persistor = persistStore(store);