import React, { useEffect, useRef } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import image from '../../../Resources/Images/754878920.webp'

const ExperiencesHero = () => {
    const heroHeaderRef = useRef(null); // Create a ref for the hero-header

    useEffect(() => {


        setTimeout(() => {
            const heroShadows = document.querySelector('.hero-shadows');
            heroShadows.classList.add('show-shadow');

            const heroShadows2 = document.querySelector('.super-shadows');
            heroShadows2.classList.add('show-shadows');
        }, 100)

        setTimeout(() => {
            const heroHeader = document.querySelector('.hero-header');
            heroHeader.classList.add('move-up');

        }, 300)

        setTimeout(() => {
            const heroHeader = document.querySelector('.hero-paragraph');
            heroHeader.classList.add('move-up');

        }, 500)


    }, [])

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo(heroHeaderRef.current,
            { y: 0, opacity: 1 }, // Starting state
            {
                y: 0, // Move up by 200px
                opacity: 0, // Fade out
                duration: 1,
                scrollTrigger: {
                    trigger: ".aboutHero-main", // Use .hero-main as the trigger for the scroll animation
                    start: "top top", // Animation will start when the top of .hero-main hits the top of the viewport
                    end: "bottom center", // Animation will end when the bottom of .hero-main exits the center of the viewport
                    scrub: 1, // Smoothly links the animation progress to the scroll position
                    markers: false,
                }
            }
        );

        document.querySelectorAll('.swiper-slide-bg').forEach(slideBg => {
            gsap.fromTo(slideBg,
                { y: 0 },
                {
                    y: 250, // Move up by 200px
                    duration: 1,
                    scrollTrigger: {
                        trigger: slideBg, // Each .swiper-slide-bg is its own trigger
                        start: "top top", // Animation will start when the top of the slideBg is at the bottom of the viewport
                        end: "bottom top", // Animation will end when the top of the slideBg exits the top of the viewport
                        scrub: 1, // Smoothly links the animation progress to the scroll position
                        markers: false,
                    }
                }
            );
        });



    }, []);

    return (
        <div className='aboutHero-main'>
            <div
                className='swiper-slide-bg'
                style={{ backgroundImage: `url(${image})` }}
            ></div>

            <div className='hero-shadows'>

                <div className='hero-wrapper' ref={heroHeaderRef}>
                    <div className='text-wrapprer' >
                        <h1 className='hero-header' >
                            EXPERIENCES
                        </h1>
                    </div>
                    <div className='text-wrapprer'>
                        <p className='hero-paragraph'>
                            Transformative Journeys Shaping Our Legacy
                        </p>
                    </div>



                </div>
            </div>

            <div className='super-shadows'>

            </div>
        </div>
    )
}

export default ExperiencesHero
