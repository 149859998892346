import React from 'react'
import './Business.css'

const Business = () => {
  return (
    <div className='business-main'>
      <h3 className='expertise-sub businesssub'>Business information:</h3>
      <p className='business-info'>Company: <span className='boldovano'>Media M PL</span>, SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ, Adress: Ul. Rodziny Hiszpanskich 8, lok. ID 1.04, 02-685 Warszawa - Poland,<br/>
NIP (the Tax Identification Number): <span className='boldovano'>PL1133106778</span>, REGON (the statistical identification number): 52623056600000, KRS (National Court Register): 0001055137, <br/>
Registration date in KRS: 2023-08-30,<br/>
PKO Bank Polski: PL56 1020 1185 0000 4102 0368 7738 <span className='boldovano'>PLN</span>, PL34 1020 1185 0000 4102 0368 7746 <span className='boldovano'>EUR</span>, PL12 1020 1185 0000 4302 0368 7753 <span className='boldovano'>USD</span>, BIC (SWIFT): BPKOPLPWXXX</p><br/>
    </div>
  )
}






export default Business
