import React, { useEffect, useRef, useState } from 'react';
import './LoadingPage.scss'
import { useDispatch } from 'react-redux';
import { fetchProducts } from '../../Store/Slices/productsSlice';
import { fetchTeamMembers } from '../../Store/Slices/teamSlice';

import logo from '../../Resources/Images/logosmoll.png'
import gsap from 'gsap';
import { setLoading } from '../../Store/Actions/loadingActions'; // Import the action
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Helmet } from 'react-helmet-async';


const LoadingPage = () => {
    const dispatch = useDispatch();
    const logoRef = useRef(null); // Create a ref for the logo
    const [shadowOff, setShadowOff] = useState(false); // State to manage the shadow class
    const [showButton, setShowButton] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        dispatch(fetchProducts());
        dispatch(fetchTeamMembers());

        gsap.fromTo(logoRef.current,
            { scale: 0 },
            { scale: 1, ease: "bounce.out", duration: 1 }
        );


        const timer = setTimeout(() => {
            setShowButton(true);
        }, 2000);

        // Cleanup the timeout if the component unmounts
        return () => clearTimeout(timer);


    }, [dispatch]);


    const handleButtonClick = () => {
        const timer = setTimeout(() => {
            setShadowOff(true);
        }, 0);

        setTimeout(() => {
            dispatch(setLoading(false)); // Use the action to update LoadingValue
        }, 300);

        // Cleanup the timeout if the component unmounts
        return () => clearTimeout(timer);
    };

    const canvasRef = useRef(null);
    const largeHeaderRef = useRef(null); // Add this line


    useEffect(() => {
        let width, height, canvas, ctx, points, target, animateHeader = true;

        const initHeader = () => {
            width = window.innerWidth;
            height = window.innerHeight;
            target = { x: width / 2, y: height / 2 };

            largeHeaderRef.current.style.height = `${height}px`;

            canvas = canvasRef.current;
            canvas.width = width;
            canvas.height = height;
            ctx = canvas.getContext('2d');

            points = [];
            for (let x = 0; x < width; x += width / 10) {
                for (let y = 0; y < height; y += height / 20) {
                    const px = x + Math.random() * width / 10;
                    const py = y + Math.random() * height / 20;
                    const p = { x: px, originX: px, y: py, originY: py };
                    points.push(p);
                }
            }

            for (let i = 0; i < points.length; i++) {
                const closest = [];
                const p1 = points[i];
                for (let j = 0; j < points.length; j++) {
                    const p2 = points[j];
                    if (p1 !== p2) {
                        let placed = false;
                        for (let k = 0; k < 5; k++) {
                            if (!placed) {
                                if (closest[k] === undefined) {
                                    closest[k] = p2;
                                    placed = true;
                                }
                            }
                        }

                        for (let k = 0; k < 5; k++) {
                            if (!placed) {
                                if (getDistance(p1, p2) < getDistance(p1, closest[k])) {
                                    closest[k] = p2;
                                    placed = true;
                                }
                            }
                        }
                    }
                }
                p1.closest = closest;
            }

            for (let i in points) {
                const c = new Circle(points[i], 2 + Math.random() * 2, 'rgba(116, 116, 116,0.3)');
                points[i].circle = c;
            }
        };

        const addListeners = () => {
            if (!('ontouchstart' in window)) {
                window.addEventListener('mousemove', mouseMove);
            }
            window.addEventListener('scroll', scrollCheck);
            window.addEventListener('resize', resize);
        };

        const mouseMove = (e) => {
            let posx = 0, posy = 0;
            if (e.pageX || e.pageY) {
                posx = e.pageX;
                posy = e.pageY;
            } else if (e.clientX || e.clientY) {
                posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }
            target.x = posx;
            target.y = posy;
        };

        const scrollCheck = () => {
            if (document.body.scrollTop > height) animateHeader = false;
            else animateHeader = true;
        };

        const resize = () => {
            width = window.innerWidth;
            height = window.innerHeight;
            largeHeaderRef.current.style.height = `${height}px`;
            canvas.width = width;
            canvas.height = height;
        };

        const initAnimation = () => {
            animate();
            for (let i in points) {
                shiftPoint(points[i]);
            }
        };

        const animate = () => {
            if (animateHeader) {
                ctx.clearRect(0, 0, width, height);

                // Dynamically adjust thresholds based on screen width
                const baseWidth = 1920; // Example base width for standard desktop screen
                const scaleFactor = window.innerWidth / baseWidth;
                const dynamicThreshold1 = 8000 * scaleFactor;
                const dynamicThreshold2 = 32000 * scaleFactor;
                const dynamicThreshold3 = 64000 * scaleFactor;

                for (let i in points) {
                    const distance = Math.abs(getDistance(target, points[i]));
                    if (distance < dynamicThreshold1) {
                        points[i].active = 0.3;
                        points[i].circle.active = 0.6;
                    } else if (distance < dynamicThreshold2) {
                        points[i].active = 0.1;
                        points[i].circle.active = 0.3;
                    } else if (distance < dynamicThreshold3) {
                        points[i].active = 0.02;
                        points[i].circle.active = 0.1;
                    } else {
                        points[i].active = 0;
                        points[i].circle.active = 0;
                    }

                    drawLines(points[i]);
                    points[i].circle.draw();
                }
            }
            requestAnimationFrame(animate);
        };

        const shiftPoint = (p) => {
            gsap.to(p, 1 + 1 * Math.random(), {
                x: p.originX - 50 + Math.random() * 100,
                y: p.originY - 50 + Math.random() * 100,
                ease: "circ.inOut", // Updated to use string notation for ease
                onComplete: () => {
                    shiftPoint(p);
                }
            });
        };


        const drawLines = (p) => {
            if (!p.active) return;
            for (let i in p.closest) {
                ctx.beginPath();
                ctx.moveTo(p.x, p.y);
                ctx.lineTo(p.closest[i].x, p.closest[i].y);
                ctx.strokeStyle = `rgba(116, 116, 116,${p.active})`;
                ctx.stroke();
            }
        };

        const getDistance = (p1, p2) => {
            return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
        };

        class Circle {
            constructor(pos, rad, color) {
                this.pos = pos;
                this.radius = rad;
                this.color = color;
                this.active = 0;
            }

            draw() {
                if (!this.active) return;
                ctx.beginPath();
                ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
                ctx.fillStyle = `rgba(116, 116, 116,${this.active})`;
                ctx.fill();
            }
        }

        // Initialize everything
        initHeader();
        initAnimation();
        addListeners();

        // Cleanup function
        return () => {
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('scroll', scrollCheck);
            window.removeEventListener('resize', resize);
        };
    }, []);


    const [progress, setProgress] = useState(0);
    const [color, setColor] = useState('8C0303');

    useEffect(() => {
        const totalDuration = 2000; // Total duration in milliseconds
        const updateCount = 100; // Number of updates (0 to 100%)
        const intervalDuration = totalDuration / updateCount; // Time per update

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 1; // Increment progress
                } else {
                    clearInterval(interval); // Stop the interval when progress reaches 100
                    return prevProgress; // Keep progress at 100
                }
            });
        }, intervalDuration);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);

    // Calculate stroke-dasharray for SVG circle
    const radius = 37.5;
    const circumference = radius * 2 * Math.PI;
    const strokeDasharray = `${(circumference * progress) / 100}, ${circumference}`;

    const handleNavigation = (path) => {
        window.scrollTo({
            top: 0,
        });

        navigate(path); // Navigate to the specified path
    };


    return (
        <>

            <Helmet>
                <title>Media M</title>
                <meta name='description' content='Leading PR Consulting services focused on innovative brand imaging and public relations development to enhance connectivity and market presence.' />
                <link rel='canonical' href='/' />
                <meta name="keywords" content="Media M, Media M PL SP ZOO, Media M Slovenia, Media M Poland, Media M slo, Business Consulting, Business Counselling, Food Tech Slovenia, 3D Food printer slovenia, Food Fabricator Slovenia, Healthy food, 3D Food printer Slovenia, IoT, AI 3D Food Printer" />

            </Helmet>

            <div className='loading-page-main'>

                <div className="container demo-1">
                    <div ref={largeHeaderRef} className="large-header">
                        <canvas ref={canvasRef} id="demo-canvas"></canvas>
                    </div>
                </div>


                <div className='loading-content-wrapper'>

                    <img src={logo} className='logo-img' ref={logoRef} alt='log' />

                    <div className="container">
                        <svg width="75" height="75">
                            <circle
                                className="progress"
                                r="35"
                                cx="37.5"
                                cy="37.5"
                                fill="none"
                                stroke={`#${color}`}
                                strokeDasharray={strokeDasharray}
                            />
                            <circle className="center" r="35" cx="37.5" cy="37.5" fill="none" />
                            <text textAnchor="middle" x="37.5" y="43">{`${progress}%`}</text>
                        </svg>
                    </div>

                    <div className='the-button-wrapper'>
                        <button className={`goinbutton ${showButton ? 'showbutton' : ''}`} onClick={() => handleNavigation('/Projects')}>ENTER</button>
                    </div>


                </div>

                <div className={`shadows-div ${shadowOff ? 'shadowoff' : ''}`}></div>

            </div>

        </>
    )
}

export default LoadingPage
