import React from 'react';
import './AboutWork.css';
import a1 from '../../../../Resources/Images/a1.png'
import a2 from '../../../../Resources/Images/a3.png'
import a3 from '../../../../Resources/Images/a4.png'
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AboutWork = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleNavigation = (path) => {
        window.scrollTo({
            top: 0,
        });

        navigate(path); // Navigate to the specified path
    };
    return (
        <>
            <div className='about-work-main'>
                <div className='row-wrapper'>
                    <div className='about-work-row'>
                        <div className='about-left1'>
                            <img className='about-img' src={a1} alt='a1' />
                        </div>
                        <div className='about-right1'>
                            <div className='about-content-wrapper'>
                                <h3 className='a-expertise-sub'>Proactive Solutions for Dynamic Challenges</h3>
                                <p className='a-expertise-p'>Your business doesn't operate in a vacuum, and neither do we. Our global reach and multifaceted expertise mean we're equipped to tackle challenges across a spectrum of industries. We're more than consultants; we're your strategic partners in problem-solving. Whether it's navigating market changes, leveraging technology for efficiency, or understanding the pulse of your target audience, our team is ready to guide you through. Because when it comes to success, the right connections and insights can make all the difference.</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='row-wrapper'>
                    <div className='about-work-row'>
                        <div className='about-left1 mobilni'>
                            <img className='about-img' src={a2} alt='a1' />
                        </div>
                        <div className='about-right1'>
                            <div className='about-content-wrapper'>
                                <h3 className='a-expertise-sub'>Tailored Strategies for Unique Visions</h3>
                                <p className='a-expertise-p'>How many experts do you need? Who exactly do you need? With us, the answer is always: exactly as many as it takes to achieve your goals. Our approach is highly personalized, matching our team and strategies to the unique needs and ambitions of your company. From one-off consultations to comprehensive project management, we adapt our solutions to fit you perfectly. This flexibility is our strength, ensuring that we're always ready to pivot and innovate in response to your evolving requirements.</p>
                            </div>
                        </div>

                        <div className='about-left1 desktopi'>
                            <img className='about-img' src={a2} alt='a1' />
                        </div>
                    </div>
                </div>

                <div className='row-wrapper'>
                    <div className='about-work-row'>
                        <div className='about-left1'>
                            <img className='about-img' src={a3} alt='a1' />
                        </div>
                        <div className='about-right1'>
                            <div className='about-content-wrapper'>
                                <h3 className='a-expertise-sub'>Crafting a Resonant Brand Identity</h3>
                                <p className='a-expertise-p'>A compelling brand image is the cornerstone of customer connection and market distinction. Let's delve into the elements that make your brand unforgettable:</p>
                                <div className='bullet-row'>
                                    <div className='bullet'></div>
                                    <p className='a-expertise-p pmargin'>Logo and Brand Identity</p>

                                </div>
                                <div className='bullet-row'>
                                    <div className='bullet'></div>
                                    <p className='a-expertise-p pmargin'>Digital Presence</p>

                                </div>
                                <div className='bullet-row'>
                                    <div className='bullet'></div>
                                    <p className='a-expertise-p pmargin'>Product Packaging and Visual Materials</p>

                                </div>
                                <div className='bullet-row'>
                                    <div className='bullet'></div>
                                    <p className='a-expertise-p pmargin'>Physical Storefront and Customer Experience</p>

                                </div>
                                <div className='bullet-row'>
                                    <div className='bullet'></div>
                                    <p className='a-expertise-p pmargin'>Professionalism and Team Presentation</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className='expertise-main'>
                <h2 className='products-name my-team'>Let’s Make It Happen Together</h2>
                <h3 className='expertise-sub'>Your Vision, Our Mission</h3>
                <p className='expertise-p'>Ready to transform your brand and elevate your business? We're here to make it happen. With a deep understanding of your needs and the industry's best practices, our team is your gateway to innovation and success. From strategic planning to execution, we're with you every step of the way. Let's embark on this journey together—because your potential is unlimited, and together, we can unlock it.</p>
                <div className='btn-wrapper'>
                    <button className="show-more-btn btn-margin" onClick={() => handleNavigation('/Contact')}>Contact</button>
                </div>
            </div>
        </>
    )
}

export default AboutWork
