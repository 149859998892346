import React from 'react'

const Intro = () => {
  return (
    <div className='expertise-main'>
        <h2 className='products-name my-team'>Welcome to Our Contact Page</h2>
        
        <p className='expertise-p'>At Media-M, we believe in open lines of communication and are here to provide you with the support and information you need. Whether you're looking to connect with us in Poland or Slovenia, have general inquiries, or simply want to share your thoughts, we're just a message away. Our team is dedicated to ensuring your experience with us is nothing short of exceptional. Below, you'll find all the necessary details to get in touch with us, including our office locations, contact forms, and direct lines. Your questions, feedback, and insights are invaluable to us as we strive to serve you better and foster lasting relationships. Let's start the conversation today!</p>
    </div>
  )
}

export default Intro
