import React, { useEffect, useState, useRef } from 'react';
import './Ekipa.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTeamMembers } from '../../../../Store/Slices/teamSlice';

const Ekipa = () => {
  const team = useSelector((state) => state.persisted.team.members);
  const loading = useSelector((state) => state.persisted.team.loading);
  const dispatch = useDispatch();

  const [selectedMember, setSelectedMember] = useState(null);
  const chosenContainerRef = useRef(null); 

  useEffect(() => {
    // Fetch team members only if they are not already loaded
    if (!team || team.length === 0) {
      dispatch(fetchTeamMembers());
    }
  }, [dispatch, team]);

  useEffect(() => {
    
    if (team.length > 0) {
      setSelectedMember(team[0]);
    }
  }, [team]);

  const handleSelectMember = (member) => {
    if (chosenContainerRef.current) {
      chosenContainerRef.current.classList.add('blink');
      setTimeout(() => {
        setSelectedMember(member);
      }, 250); 

      setTimeout(() => {
        chosenContainerRef.current.classList.remove('blink');
      }, 1000);
    }
  };

  return (
    <div className='ekipa-main'>
      <h1 className='products-name my-team'>OUR TEAM</h1>
      
      {loading ? (
        <div className="loading-message"><div className="loader"></div></div>
      ) : (
        <>
          <div className='bigger-circle'>
            <div className='chosen-container'>
              {selectedMember && (
                <>
                  <div className='ekipa-circle chosen' ref={chosenContainerRef} style={{ backgroundImage: `url(${selectedMember.src})` }}></div>
                  <p className='member-name'>{selectedMember.acf.name}</p>
                  <p className='member-profession'>{selectedMember.acf.profession}</p>
                  <div className='icons-row'>
                    <div className='icon-wrapper'>
                      <a href={`tel:${selectedMember.acf.phone}`}>
                        <i className="fas fa-phone"></i>
                      </a>
                    </div>
                    <div className='icon-wrapper'>
                      <a href={`mailto:${selectedMember.acf.email}`}>
                        <i className="fas fa-envelope"></i>
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className='ekipa-row'>
            {team.map((member) => (
              <div 
                key={member.id}
                className={`ekipa-circle ${member.id === selectedMember?.id ? '' : 'bw'}`}
                style={{ backgroundImage: `url(${member.src})` }}
                onClick={() => handleSelectMember(member)}>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Ekipa;