import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import ExperiencesHero from './Components/ExperiencesHero'
import ExperiencesReferences from './Components/ExperiencesReferences/ExperiencesReferences'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet-async'

const Experiences = () => {
  return (
    <div>
      <>
      <Helmet>
                <title>Experiences</title>
                <meta name='description' content='Dive into our rich portfolio of successful PR campaigns. See how our innovative approaches in brand imaging and public relations have elevated clients across industries.'/>
                <link rel='canonical' href='/Experiences'/>
                <meta name="keywords" content="Media M, Media M PL SP ZOO, Media M Slovenia, Media M Poland, Media M slo, Business Consulting, Business Counselling, Food Tech Slovenia, 3D Food printer slovenia, Food Fabricator Slovenia, Healthy food, 3D Food printer Slovenia, IoT, AI 3D Food Printer" />

            </Helmet>
        <Navbar/>
        <ExperiencesHero/>
        <ExperiencesReferences/>
        <Footer/>
        </>
    </div>
  )
}

export default Experiences
