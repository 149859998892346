import React from 'react'
import a1 from '../../../../Resources/Images/m1.png'
import a2 from '../../../../Resources/Images/m2.png'
import a3 from '../../../../Resources/Images/m3.png'
import a4 from '../../../../Resources/Images/m4.png'
import './PmCore.css'
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const PmCore = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleNavigation = (path) => {
        window.scrollTo({
            top: 0,
        });

        navigate(path); // Navigate to the specified path
    };
  return (
    <div>
      <div className='about-work-main'>

            <div className='row-wrapper'>
                <div className='about-work-row'>
                    <div className='about-left1'>
                        <img className='about-img' src={a4} alt='a1' />
                    </div>
                    <div className='about-right1'>
                        <div className='about-content-wrapper'>
                            <h3 className='a-expertise-sub'>The Pillars of Product Management</h3>
                            <p className='a-expertise-p'>At the core of product management is the strategic orchestration of a product's roadmap, from ideation to market introduction. Product Managers embody the role of strategists, innovators, and leaders, taking ownership of the product roadmap and ensuring its alignment with business goals. They are tasked with the execution of product strategies, driving cross-functional teams to market success, and overseeing the product's profitability and commercial viability.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className='row-wrapper'>
                <div className='about-work-row'>
                    <div className='about-left1 mobilni'>
                        <img className='about-img' src={a1} alt='a1' />
                    </div>
                    <div className='about-right1'>
                        <div className='about-content-wrapper'>
                            <h3 className='a-expertise-sub'>Product Manager: The Strategy Behind Success</h3>
                            <p className='a-expertise-p'>The Product Manager is the visionary, steering the product through its lifecycle with a keen eye on the market and competitive landscape. They translate business strategies into actionable product plans, ensuring that every feature and update serves the company's overarching goals. From planning launches to managing commercial success, the Product Manager is the architect of the product's journey.</p>
                        </div>
                    </div>

                    <div className='about-left1 desktopi'>
                        <img className='about-img' src={a1} alt='a1' />
                    </div>
                </div>
            </div>

            <div className='row-wrapper'>
                <div className='about-work-row'>
                    <div className='about-left1'>
                        <img className='about-img' src={a2} alt='a1' />
                    </div>
                    <div className='about-right1'>
                        <div className='about-content-wrapper'>
                            <h3 className='a-expertise-sub'>Product Owner: Agile's Visionary</h3>
                            <p className='a-expertise-p'>In the Agile framework, the Product Owner is the team's compass, providing a prioritized backlog of features and guiding the development process with a clear vision. Acting as the customer's proxy, they ensure that the product development aligns with user needs and expectations, maintaining a balance between long-term goals and short-term agility.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className='row-wrapper'>
                <div className='about-work-row'>
                    <div className='about-left1 mobilni'>
                        <img className='about-img' src={a3} alt='a1' />
                    </div>
                    <div className='about-right1'>
                        <div className='about-content-wrapper'>
                            <h3 className='a-expertise-sub'>Product Marketing Manager: Crafting the Message</h3>
                            <p className='a-expertise-p'>The Product Marketing Manager bridges the gap between the product and its audience, crafting compelling messages that highlight the product's unique value proposition. They are experts in the market, understanding buyer personas, competitive dynamics, and the sales process to ensure that the product stands out in a crowded marketplace.</p>
                        </div>
                    </div>

                    <div className='about-left1 desktopi'>
                        <img className='about-img' src={a3} alt='a1' />
                    </div>
                </div>
            </div>

            

        </div>

        <div className='expertise-main'>
        <h3 className='expertise-sub submargins'>Empowering Your Product's Journey</h3>
                
                <p className='expertise-p'>The synergy between product management roles is the catalyst for transforming innovative ideas into market-leading products. Embracing the multifaceted nature of product management equips businesses with the insight, strategy, and execution capability to navigate the complexities of the market. Let's embark on this journey together, turning visionary ideas into products that captivate and inspire.</p>
                <div className='btn-wrapper'>
                    <button className="show-more-btn btn-margin" onClick={() => handleNavigation('/Contact')}>Contact</button>
                </div>
            </div>
    </div>
  )
}

export default PmCore
