import React from 'react'
import './ExperiencesReferences.css'
import e1 from '../../../../Resources/Images/e1.jpg'
import e2 from '../../../../Resources/Images/e2.jpg'
import e3 from '../../../../Resources/Images/e3.jpg'
import e4 from '../../../../Resources/Images/e4.jpg'
import e5 from '../../../../Resources/Images/e5.jpg'
import e6 from '../../../../Resources/Images/e6.jpg'
import e7 from '../../../../Resources/Images/e6.png'
import e8 from '../../../../Resources/Images/e7.jpg'
import e9 from '../../../../Resources/Images/e8.jpg'
import e10 from '../../../../Resources/Images/e9.png'
import e11 from '../../../../Resources/Images/e10.jpg'
import e12 from '../../../../Resources/Images/e11.jpg'
import e13 from '../../../../Resources/Images/e12.jpg'
import e14 from '../../../../Resources/Images/e13.jpg'
import e15 from '../../../../Resources/Images/e14.jpg'
import e16 from '../../../../Resources/Images/e15.png'
import MT from '../../../../Resources/Images/MT.png'
import MMTV from '../../../../Resources/Images/MMTV.png'



const ExperiencesReferences = () => {
    return (
        <div className='references-main'>
            <h1 className='products-name my-team refmargin'>Milestones of Innovation and Excellence</h1>
            <div className='references-row'>
                <div className='references-left'>
                    <div className='about-content-wrapper space-wrapper'>
                        <div className='ref-upper'>
                            <p className='upper-header'>UNIGRANMER - SIVEC COMEX marble mine in Macedonia</p>
                            <p className='upper-p'>
                                Consulting, Promotion, Expansion, Ownership (family business).</p>
                            <a href='http://www.unigranmer.com/'>http://www.unigranmer.com/</a><br></br>
                            <a href='http://www.sivec-comex.si/'>http://www.sivec-comex.si/</a>
                        </div>
                        <div className='ref-down'>
                            <div className='reimg-wrapper'>
                                <img src={e1} className='reference-img' />
                            </div>

                        </div>

                    </div>
                </div>
                <div className='references-right'>
                    <img className='reference-img' src={e2} />
                </div>

            </div>

            <div className='references-row'>
                <div className='references-left'>
                    <div className='about-content-wrapper space-wrapper'>
                        <div className='ref-upper'>
                            <p className='upper-header'>FABRKA d.o.o. Audio, Video, Web, Multimedia and Pos Production</p>
                            <p className='upper-p'>
                                Managment, Promotion, Expansion​, Part Ownership.</p>
                            <a href='http://www.fabrka.com/'>http://www.fabrka.com/</a><br></br>
                        </div>
                        <div className='ref-down scalelogo'>
                            <div className='reimg-wrapper'>
                                <img src={e3} className='reference-img' />
                            </div>

                        </div>

                    </div>
                </div>
                <div className='references-right'>
                    <img className='reference-img' src={e4} />
                </div>

            </div>

            <div className='references-row'>
                <div className='references-left'>
                    <div className='about-content-wrapper space-wrapper'>
                        <div className='ref-upper'>
                            <p className='upper-header'>Radio Gama MM now Radio Ekspres</p>
                            <p className='upper-p'>
                                Managment, Promotion, Expansion​, Ownership (family business).</p>
                            <a href='http://www.radioekspres.si/'>http://www.radioekspres.si/</a><br></br>
                            <a href='http://www.gamamm.si/'>http://www.gamamm.si/</a>
                        </div>
                        <div className='ref-down scalelogo'>
                            <div className='reimg-wrapper'>
                                <img src={e5} className='reference-img' />
                            </div>

                        </div>

                    </div>
                </div>
                <div className='references-right'>
                    <img className='reference-img' src={e6} />
                </div>

            </div>

            <div className='references-row'>
                <div className='references-left'>
                    <div className='about-content-wrapper space-wrapper'>
                        <div className='ref-upper'>
                            <p className='upper-header'>Telemach d.o.o.</p>
                            <p className='upper-p'>
                                Consulting, Promotion, Expansion​, Part Ownership.
                                The biggest Telecommunication company in Slovenia.</p>
                            <a href='http://www.telemach.si/'>http://www.telemach.si/</a><br></br>
                        </div>
                        <div className='ref-down'>
                            <div className='reimg-wrapper'>
                                <img src={e7} className='reference-img' />
                            </div>

                        </div>

                    </div>
                </div>
                <div className='references-right'>
                    <img className='reference-img' src={e8} />
                </div>

            </div>

            <div className='references-row'>
                <div className='references-left'>
                    <div className='about-content-wrapper space-wrapper'>
                        <div className='ref-upper'>
                            <p className='upper-header'>University Medical Centre Ljubljana</p>
                            <p className='upper-p'>
                                Consulting.</p>
                            <a href='http://www.ukc.si'>http://www.ukc.si</a><br></br>
                        </div>
                        <div className='ref-down scalelogo'>
                            <div className='reimg-wrapper'>
                                <img src={e9} className='reference-img' />
                            </div>

                        </div>

                    </div>
                </div>
                <div className='references-right'>
                    <img className='reference-img' src={e15} />
                </div>

            </div>

            <div className='references-row'>
                <div className='references-left'>
                    <div className='about-content-wrapper space-wrapper'>
                        <div className='ref-upper'>
                            <p className='upper-header'>POP TV & Kanal A - Pro Plus d.o.o.</p>
                            <p className='upper-p'>
                                Traffic Management, Program, Promotion, Part Ownership.
                                The most popular and biggest TV station in Slovenia</p>
                            <a href='http://www.pop-tv.si/'>http://www.pop-tv.si/</a><br></br>
                        </div>
                        <div className='ref-down'>
                            <div className='reimg-wrapper'>
                                <img src={e16} className='reference-img' />
                            </div>

                        </div>

                    </div>
                </div>
                <div className='references-right'>
                    <img className='reference-img' src={e10} />
                </div>

            </div>

            <div className='references-row'>
                <div className='references-left'>
                    <div className='about-content-wrapper space-wrapper'>
                        <div className='ref-upper'>
                            <p className='upper-header'>Meglič Telecom d.o.o. now Telemach</p>
                            <p className='upper-p'>
                                Consulting, Promotion, Expansion, Ownership (family business).</p>
                            <a href='http://www.mmtc.si/'>http://www.mmtc.si/</a><br></br>
                        </div>
                        <div className='ref-down scalelogo'>
                            <div className='reimg-wrapper'>
                                <img src={MT} className='reference-img' />
                            </div>

                        </div>

                    </div>
                </div>
                <div className='references-right'>
                    <img className='reference-img' src={e12} />
                </div>

            </div>

            <div className='references-row'>
                <div className='references-left'>
                    <div className='about-content-wrapper space-wrapper'>
                        <div className='ref-upper'>
                            <p className='upper-header'>MMTV d.o.o. now POP TV</p>
                            <p className='upper-p'>

                                Consulting, Promotion, Expansion, Ownership (family business).</p>

                            <p className='upper-p'>

                                <span className='bold'>Marjan Meglič</span> is considered a "pioneer of Slovenian private electronic media", he was the owner of Meglič Telecom "predecessor of Telemach" MM TV - Channel 62, the "predecessor of Pop TV", and Radio MM which become Radio Gama MM 106,4FM "predecessor of Radio Ekspres".</p>
                            <a href='http://www.mmtv.si/'>http://www.mmtv.si/</a><br></br>
                        </div>
                        <div className='ref-down scalelogo'>
                            <div className='reimg-wrapper'>
                                <img src={MMTV} className='reference-img' />
                            </div>

                        </div>

                    </div>
                </div>
                <div className='references-right'>
                    <img className='reference-img' src={e14} />
                </div>

            </div>

        </div>
    )
}

export default ExperiencesReferences
