import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const fetchImageLinkById = async (imageId) => {
    try {
      const response = await axios.get(`https://woocommerce.media-m.si/wp-json/wp/v2/media/${imageId}`);
      // Assuming the image link is in the response's 'source_url' property
      return response.data.source_url;
    } catch (error) {
      console.error(`Failed to fetch image with ID ${imageId}:`, error);
      return null; // or a default image link if you have one
    }
  };

// Define the thunk for fetching team members
export const fetchTeamMembers = createAsyncThunk('team/fetchTeamMembers', async () => {
    const teamUrl = `${process.env.REACT_APP_API_URL}/team`;
    const response = await axios.get(teamUrl);
    
    // First, sort teamMembers by acf.id
    let teamMembers = response.data.sort((a, b) => {
      const idA = a.acf && a.acf.id ? a.acf.id : 0;
      const idB = b.acf && b.acf.id ? b.acf.id : 0;
      return idA - idB;
    });
  
    // Then, fetch and assign image links
    const processedTeamMembers = await Promise.all(teamMembers.map(async (member) => {
      if (member.acf && member.acf.image) {
        const imageLink = await fetchImageLinkById(member.acf.image);
        return { ...member, src: imageLink };
      }
      return member;
    }));
  
    return processedTeamMembers;
  });

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    members: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Define any synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeamMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload;
      })
      .addCase(fetchTeamMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default teamSlice.reducer;